





















import BuybackBurn from '@/components/BuybackBurn.vue'
import BuybackBurnList from '@/components/BuybackBurnList.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import { getAPIConfiguration, getAccounts } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'

@Component({
	components: {
		BuybackBurn,
	  BuybackBurnList
	},
})
export default class BuybackBurnView extends Vue {
	accounts: Account[] = []
	conf: Conf | null = null
	loading = true

	async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
	  this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.loading = false
	}
}
