







































import { Component, Vue } from 'vue-property-decorator'
import { getBBBList } from '@/clients/cpinblocks'
import { BBBListItem } from '@/models/BuybackBurnListItem'
import { BigNumber } from 'bignumber.js'

@Component
export default class BuybackBurnList extends Vue {
	bbbItems: BBBListItem[] | null = []

	headers = [
		{
			align: 'left',
			sortable: true,
			text: this.$t('buybackBurnList.table.presaleRank'),
			value: 'presaleRank',
		},
		{
			align: 'left',
			sortable: true,
			text: this.$t('buybackBurnList.table.actualRank'),
			value: 'rank',
		},
		{
			align: 'left',
			sortable: true,
			text: this.$t('buybackBurnList.table.id'),
			value: 'civicId',
		},
		{
			align: 'left',
			sortable: true,
			text: this.$t('buybackBurnList.table.redeemed'),
			value: 'redeemed',
		},
		{
			align: 'left',
			sortable: true,
			text: this.$t('buybackBurnList.table.total'),
			value: 'total',
		},
		{
			align: 'left',
			sortable: true,
			text: this.$t('buybackBurnList.table.percent'),
			value: 'percent',
		},
	]
	loading = true

	async mounted (): Promise<void> {
		this.bbbItems = await getBBBList()
		if (this.bbbItems) {
			for (let item of this.bbbItems) {
				item.total = new BigNumber(item.redeemed).plus(new BigNumber(item.redeemable))
				item.percent = new BigNumber(new BigNumber(item.redeemed).multipliedBy(100).div(item.total).toPrecision(4))
			}
		}
		this.loading = false
	}

	openInNewTab (url: string): void {
		window.open(url, '_blank')
	}
}
