











































































































































import Code2FADialog from '@/components/Code2FADialog.vue'
import { BuybackBurn } from '@//models/BuybackBurn'
import { BuybackConf } from '@/models/Conf'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Account } from '@/models/Account'
import BigNumber from 'bignumber.js'
import { getBBB, pullBBB, pushBBB } from '@/clients/cpinblocks'

@Component({
	components: { Code2FADialog },
})
export default class BuybackBurnComponent extends Vue {
	@Prop() buybackBurnConf!: BuybackConf
	@Prop() accounts!: Account[]
	@Prop() id!: number

	account: Account | null = null
	buybackBurn?: BuybackBurn
	amount = 0
	amountICO = 0
	balanceICO: BigNumber = new BigNumber('0')
	balanceMAIN: BigNumber = new BigNumber('0')
	creating = false
	delayFromStartSec = 0
	delayFromEndSec = 0
	loading = true
	show2FADialogDeposit = false
	show2FADialogWithdraw = false
	softTimer?: ReturnType<typeof setTimeout>

	get currencyAmountApproximation (): BigNumber {
		const ico32 = this.buybackBurnConf.POWER_ICO.multipliedBy(this.ico32).multipliedBy(1.32)
		const ico16 = this.buybackBurnConf.POWER_ICO.multipliedBy(this.ico16).multipliedBy(1.16)
		const main32 = this.buybackBurnConf.POWER_MAIN.multipliedBy(this.main32).multipliedBy(1.32)
		const main16 = this.buybackBurnConf.POWER_MAIN.multipliedBy(this.main16).multipliedBy(1.16)
		return new BigNumber(ico32.plus(ico16).plus(main32).plus(main16).toFixed(6))
	}

	get depositEnabled (): boolean {
		if (this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD') {
			return false
		}
    return (this.amountMAIN > 0 || this.amountICO > 0) &&
			this.balanceMAIN?.gte(this.amountMAIN) &&
			this.balanceICO?.gte(this.amountICO) &&
			this.buybackBurn?.currency.max !== undefined && this.currencyAmountApproximation.lte(this.buybackBurn?.currency.max) &&
			this.buybackBurn?.status === 'PUSHABLE'
	}

	get enabledWithdraw (): boolean {
	  if (this.$store.state.owner.locked === 'HARD') {
		  return false
	  }
	  return true
	}

	get amountMAIN (): number {
		return this.amount
	}

	set amountMAIN (value: number) {
	  // do nothing
		// consequence of the hack to remove POWER ICO from the parameters
  }

	get ico32 (): BigNumber {
		return BigNumber.min(this.amountICO, this.redeemable32)
	}

	get ico16 (): BigNumber {
		return BigNumber.max(0, new BigNumber(this.amountICO).minus(this.redeemable32))
	}

	get main32 (): BigNumber {
		return BigNumber.min(new BigNumber(this.redeemable32).minus(this.ico32), this.amountMAIN)
	}

	get main16 (): BigNumber {
		return (new BigNumber(this.amountMAIN)).minus(this.main32)
	}

	get redeemable32 (): BigNumber {
		return this.buybackBurn?.redeemable32 ?? new BigNumber('0')
	}

	get redeemable16 (): BigNumber {
		return this.buybackBurn?.redeemable16 ?? new BigNumber('0')
	}

	get burned (): BigNumber {
		const icoBurned: BigNumber = this.buybackBurn?.POWER_ICO?.redeemed ? new BigNumber(this.buybackBurn?.POWER_ICO?.redeemed) : new BigNumber('0')
		return icoBurned.plus(this.buybackBurn?.POWER_MAIN?.redeemed ?? new BigNumber('0'))
	}

	get max (): number {
		return (new BigNumber(this.redeemable32)).plus(this.redeemable16).toNumber()
	}

	day (delaySec: number): string {
		return Math.floor(Math.abs(delaySec / 86400)).toString()
	}

	hour (delaySec: number): string {
		return Math.floor(Math.abs((delaySec / 3600) % 24)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	min (delaySec: number): string {
		return Math.floor(Math.abs((delaySec / 60) % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	sec (delaySec: number): string {
		return Math.floor(Math.abs(delaySec % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
	}

	get statusMessage (): string {
		let messageKey: string | null = null
		let delayReference = 0
		if (this.buybackBurn?.status === 'NOT_PUSHABLE') {
			delayReference = this.delayFromStartSec
			messageKey = this.day(delayReference) !== '0' ? 'timebeforeopening' : 'timebeforeopeningNoDay'
		} else if (this.buybackBurn?.status === 'PUSHABLE' || this.buybackBurn?.status === 'PUSHED') {
			delayReference = this.delayFromEndSec
			if (delayReference > 0) {
				messageKey = this.day(delayReference) !== '0' ? 'timeleft' : 'timeleftNoDay'
			} else {
				messageKey = 'waitingForBurn'
			}
		}
		if (messageKey !== null) {
			return this.$t('buybackburn.' + messageKey, {
				days: this.day(delayReference),
				hours: this.hour(delayReference),
				minutes: this.min(delayReference),
				seconds: this.sec(delayReference),
			}).toString()
		}
		return ''
	}

	async softRefresh (): Promise<void> {
		this.delayFromEndSec = (this.buybackBurnConf.pushEndExclusive.getTime() - new Date().getTime()) / 1000
		this.delayFromStartSec = (this.buybackBurnConf.pushStartInclusive.getTime() - new Date().getTime()) / 1000
	}

	async mounted (): Promise<void> {
		await this.refresh()
		await this.softRefresh()
		this.softTimer = setInterval(this.softRefresh, 100)
	}

	async refresh (): Promise<void> {
		this.loading = true
		this.buybackBurn = await getBBB(this.$store.state.jwt, this.id)
		this.amountICO = this.buybackBurn?.POWER_ICO?.pushed !== undefined ? new BigNumber(this.buybackBurn?.POWER_ICO?.pushed).toNumber() : 0
		this.amount = this.buybackBurn?.POWER_MAIN?.pushed !== undefined ? new BigNumber(this.buybackBurn?.POWER_MAIN?.pushed).toNumber() : 0
		for (const acc of this.accounts) {
			if (acc.currency === 'POWER') {
				if (acc.type === 'MAIN') {
					this.balanceMAIN = acc.balance
				} else if (acc.type === 'ICO') {
					this.balanceICO = acc.balance
				}
			}
		}
		this.loading = false
	}

	beforeDestroy () {
		this.softTimer && clearInterval(this.softTimer)
	}

	async deposit (object: any): Promise<void> {
		const code: string = object.code
		this.show2FADialogDeposit = false
		this.creating = true
		this.buybackBurn = await pushBBB(this.$store.state.jwt, this.id, this.amountICO, this.amountMAIN, this.currencyAmountApproximation.toNumber(), this.buybackBurnConf.currency, code)
		this.creating = false
	}

	async withdraw (object: any): Promise<void> {
		const code: string = object.code
		this.show2FADialogWithdraw = false
		this.creating = true
		this.buybackBurn = await pullBBB(this.$store.state.jwt, this.id, code)
		this.creating = false
	}
}
